import { useEffect, useRef, useState } from 'react';
import { Character } from './Character';
import { RaidDataDuel, RaidPlayer, RaidState } from './types';
import { useAnimate } from 'framer-motion';
import {
	battleHitEffects,
	battleMissEffects,
	debugging,
	endRaid,
	initRaid,
	maxEventMessages,
	startRaid,
	timePerEventMs,
	trackError
} from './App';
import { MediaEventPlayer, MediaEventPlayerRef } from './MediaEventPlayer';

const player1DefaultPos = { x: -350, y: 43 };
const player2DefaultPos = { x: 80, y: 43 };
// very short time for when we use animations as a means to change elements
const instantly = 0.001;

export function DuelView(props: {
	dungeonState: RaidState<RaidDataDuel>;
	localClockDrift: number;
	onError: (err: any) => void;
	onClick: () => void;
	extraMsgs?: string[];
}) {
	const { dungeonState, localClockDrift, onError: handleError, onClick } = props;

	const animationPlaying = useRef(false);

	const [preloadDone, setPreloadDone] = useState(false);
	const [hideDungeon, setHideDungeon] = useState(true);
	const [countdownSecs, setCountdownSecs] = useState(20);

	const [fightResult, setFightResult] = useState<'victory' | 'stalemate'>();

	const [player1, setPlayer1] = useState<RaidPlayer | null>(null);
	const [player1State, setPlayer1State] = useState<'standing' | 'running'>('running');
	const [player1Ref, animatePlayer1] = useAnimate();
	const [player1Ready, setPlayer1Ready] = useState(false);
	const [player2, setPlayer2] = useState<RaidPlayer | null>(null);
	const [player2State, setPlayer2State] = useState<'standing' | 'running'>('running');
	const [player2Ref, animatePlayer2] = useAnimate();
	const [player2Ready, setPlayer2Ready] = useState(false);
	const [playerHealth, setPlayerHealth] = useState<[number, number]>([100, 100]);
	const mediaPlayerRef = useRef<MediaEventPlayerRef>(null);

	const battleHitEffectWrapperRefs: React.RefObject<HTMLDivElement>[] = [];
	const battleHitEffectVideRefs = battleHitEffects.map(() => useRef<HTMLVideoElement | null>(null));
	const battleHitEffectAnims: (typeof animatePlayer1)[] = [];
	for (let i = 0; i < battleHitEffects.length; i++) {
		const [ref, animate] = useAnimate();
		battleHitEffectWrapperRefs.push(ref);
		battleHitEffectAnims.push(animate);
	}
	const battleMissEffectWrapperRefs: React.RefObject<HTMLDivElement>[] = [];
	const battleMissEffectVideRefs = battleMissEffects.map(() => useRef<HTMLVideoElement | null>(null));
	const battleMissEffectAnims: (typeof animatePlayer1)[] = [];
	for (let i = 0; i < battleMissEffects.length; i++) {
		const [ref, animate] = useAnimate();
		battleMissEffectWrapperRefs.push(ref);
		battleMissEffectAnims.push(animate);
	}

	const [allEventMessages, setEventMessages] = useState<string[]>([]);
	const eventMessages = allEventMessages.slice(-maxEventMessages);
	useEffect(() => {
		setEventMessages((old) => {
			const newExtraMsgs = (props.extraMsgs ?? []).filter((msg) => !old.includes(msg));
			return [...old, ...newExtraMsgs];
		});
	}, [props.extraMsgs]);

	const notifRef = useRef<HTMLAudioElement>(null);

	async function playRandomHitEffect(pos: { x: number; y: number }) {
		const effectIdx = Math.floor(Math.random() * battleHitEffectAnims.length);
		// move effect to the place of the fight
		await battleHitEffectAnims[effectIdx]!(
			battleHitEffectWrapperRefs[effectIdx].current!,
			{
				...pos,
				opacity: 1,
			},
			{ duration: instantly },
		);
		// play the effect
		await playVideoWait(battleHitEffectVideRefs[effectIdx].current!);
		//hide it again
		await battleHitEffectAnims[effectIdx]!(
			battleHitEffectWrapperRefs[effectIdx].current!,
			{
				opacity: 0,
			},
			{ duration: instantly },
		);
	}

	async function playRandomMissEffect(pos: { x: number; y: number }) {
		const effectIdx = Math.floor(Math.random() * battleMissEffectAnims.length);
		// move effect to the place of the fight
		await battleMissEffectAnims[effectIdx]!(
			battleMissEffectWrapperRefs[effectIdx].current!,
			{
				...pos,
				opacity: 1,
			},
			{ duration: instantly },
		);
		// play the effect
		await playVideoWait(battleMissEffectVideRefs[effectIdx].current!);
		//hide it again
		await battleMissEffectAnims[effectIdx]!(
			battleMissEffectWrapperRefs[effectIdx].current!,
			{
				opacity: 0,
			},
			{ duration: instantly },
		);
	}

	useEffect(
		() => {
			if (!dungeonState) {
				return;
			}

			console.log('DUEL: update with state', dungeonState.ActiveRaid?.Status, dungeonState.ActiveRaid?.Players?.length);

			if (dungeonState.ActiveRaid?.Players?.[0]) {
				setPlayer1((old) => old ?? dungeonState.ActiveRaid!.Players![0]);
			}
			if (dungeonState.ActiveRaid?.Players?.[1]) {
				setPlayer2((old) => old ?? dungeonState.ActiveRaid!.Players![1]);
			}

			switch (dungeonState.ActiveRaid?.Status) {
				case 'pending-start':
					console.log('DUEL: pending-start - showing stuff on screen');
					initRaid(dungeonState.ActiveRaid!.RaidID).catch(handleError);
					break;

				case 'waiting-for-party':
					if (!preloadDone) {
						return;
					}
					setHideDungeon(false);
					console.log('DUEL: waiting-for-party - starting countdown');
					const updateCountdown = () => {
						setCountdownSecs(() => {
							const startTime = new Date(dungeonState.ActiveRaid!.StartedAt);
							const nowUnixMs = new Date().getTime() - localClockDrift;
							const startedAtSecs = startTime.getTime() / 1000;
							const newSecs = nowUnixMs / 1000;
							const countdownLengthSecs = dungeonState.ActiveRaid!.CountdownSeconds;
							const secsRemaining = startedAtSecs - newSecs + countdownLengthSecs;
							const dungeonIsFull = dungeonState.ActiveRaid!.MaxPlayers === dungeonState.ActiveRaid!.Players?.length;
							if (secsRemaining < 0 || dungeonIsFull) {
								startRaid(dungeonState.ActiveRaid!.RaidID).catch(handleError);
								clearInterval(interval);
								return -1;
							}
							return secsRemaining;
						});
					};
					updateCountdown();
					const interval = setInterval(updateCountdown, 500);
					if (dungeonState.Settings && dungeonState.Settings.EnableNotificationSound) {
						// play the audio
						if (!notifRef.current) {
							console.error('DUEL: did not find notification current');
							return;
						}

						notifRef.current.volume = dungeonState.Settings.NotificationSoundVolume;
						notifRef.current.play();
					}
					return () => clearInterval(interval);

				case 'completed':
					if (!player1Ready || (dungeonState.ActiveRaid!.Players?.length === 2 && !player2Ready)) {
						return;
					}
					if (animationPlaying.current) {
						// don't double play
						return;
					}
					setHideDungeon(false);
					console.log('DUEL: completed - playing the raid animation');
					const playAnimation = async () => {
						animationPlaying.current = true;
						// give some time to load the last player that joined
						await new Promise((resolve) => setTimeout(resolve, 500));
						for (const [i, event] of (dungeonState.ActiveRaid!.DuelEvents ?? []).entries()) {
							if (event.Type === 'media') {
								if (event.Message) {
									setEventMessages((old) => [...old, event.Message!]);
								}
								await mediaPlayerRef.current?.play(event);
								continue;
							}
							const activePlayerIdx = dungeonState.ActiveRaid!.Players!.findIndex((player) => player.UserID === event.UserID)!;
							const otherPlayerIdx = activePlayerIdx === 0 ? 1 : 0;
							const activePlayer = dungeonState.ActiveRaid!.Players![activePlayerIdx]!;
							// const otherPlayer = dungeonState.ActiveRaid!.Players![otherPlayerIdx]!;
							const animateActivePlayer = (props: any, opts?: any) =>
								(activePlayerIdx === 0 ? animatePlayer1 : animatePlayer2)(activePlayerIdx === 0 ? player1Ref.current : player2Ref.current, props, opts);
							const animateOtherPlayer = (props: any, opts?: any) =>
								(activePlayerIdx === 0 ? animatePlayer2 : animatePlayer1)(activePlayerIdx === 0 ? player2Ref.current : player1Ref.current, props, opts);
							const activePlayerPos = activePlayerIdx === 0 ? player1DefaultPos : player2DefaultPos;
							const otherPlayerPos = otherPlayerIdx === 0 ? player1DefaultPos : player2DefaultPos;
							const setActivePlayerState = (state: 'standing' | 'running') => (activePlayerIdx === 0 ? setPlayer1State(state) : setPlayer2State(state));

							// battle effects?
							await new Promise((resolve) => setTimeout(resolve, timePerEventMs / 4));
							switch (event.Type) {
								case 'attack':
									// ensure we are on top of the other player
									await animateActivePlayer({ 'z-index': 10 + i }, { duration: instantly });
									const jumps = 4;
									// jump to the other player
									const attackPosX = otherPlayerPos.x - 30 * Math.sign(otherPlayerPos.x);
									setActivePlayerState('running');
									await animateActivePlayer({
										x: fromCurrent(
											Array.from({ length: 2 * jumps }).map((_, i) => activePlayerPos.x + ((i + 1) * (attackPosX - activePlayerPos.x)) / (2 * jumps)),
										),
										y: fromCurrent(Array.from({ length: jumps }).flatMap(() => [otherPlayerPos.y - 50, otherPlayerPos.y])),
									});
									setActivePlayerState('standing');
									// battle effect
									const effectPosX = attackPosX < 0 ? attackPosX - 150 : attackPosX - 50;
									if (event.Message !== '0') {
										await playRandomHitEffect({ x: effectPosX, y: otherPlayerPos.y + 50 });
									} else {
										await playRandomMissEffect({ x: effectPosX, y: otherPlayerPos.y + 50 });
									}
									// display result
									if (event.Health && event.Health.length) {
										setPlayerHealth(event.Health);
									}
									if (event.Message === '0') {
										setEventMessages((old) => [...old, `${activePlayer.Name} missed`]);
									} else {
										setEventMessages((old) => [...old, `${activePlayer.Name} dealt ${event.Message} damage`]);
									}
									// jump back
									setActivePlayerState('running');
									await animateActivePlayer({
										x: fromCurrent(Array.from({ length: 2 * jumps }).map((_, i) => attackPosX - ((i + 1) * (attackPosX - activePlayerPos.x)) / (2 * jumps))),
										y: fromCurrent(Array.from({ length: jumps }).flatMap(() => [activePlayerPos.y - 50, activePlayerPos.y])),
									});
									setActivePlayerState('standing');
									break;
								case 'victory':
									setFightResult('victory');
									setEventMessages((old) => [...old, event.Message]);
									// fade out the other player
									const fadeOut = animateOtherPlayer({ opacity: 0 }, { duration: 1 });
									const winnerDance = (async () => {
										// walk to center
										const centerPos = { x: (activePlayerPos.x + otherPlayerPos.x) / 2, y: activePlayerPos.y };
										setActivePlayerState('running');
										await animateActivePlayer(centerPos, { duration: 1 });
										setActivePlayerState('standing');
										// jump up and down
										await animateActivePlayer(
											{
												// jump five times
												y: fromCurrent(Array.from({ length: 5 }).flatMap(() => [centerPos.y - 25, centerPos.y])),
											},
											{ duration: 3 },
										);
									})();
									await Promise.all([fadeOut, winnerDance]);
									break;
								case 'stalemate':
									setFightResult('stalemate');
									// animation?
									setEventMessages((old) => [...old, event.Message]);
									break;
								case 'infamy':
									// animation?
									setEventMessages((old) => [...old, `${activePlayer.Name} ${Number(event.Message) >= 0 ? 'gained' : 'lost'} ${event.Message} infamy`]);
									break;
								default:
									if (event.Message) {
										setEventMessages((old) => [...old, event.Message]);
									}
									break;
							}
						}
						await new Promise((resolve) => setTimeout(resolve, 2_500));
						setHideDungeon(true);
						await new Promise((resolve) => setTimeout(resolve, 500));
						endRaid(dungeonState.ActiveRaid!.RaidID).catch(handleError);
						console.log('DUEL: animation done');
					};
					playAnimation();
					break;

				case 'archived':
					console.log('DUEL: archived - this should not reach the frontend');
					break;

				default:
					console.error('DUEL: unknown state', dungeonState.ActiveRaid?.Status);
					break;
			}
		},
		// ensure we don't update for irrelevant changes
		[dungeonState?.ActiveRaid?.Status, dungeonState?.ActiveRaid?.Players?.length, player1Ready, player2Ready, preloadDone],
	);

	async function player1Loaded() {
		// move off-screen
		await animatePlayer1(player1Ref.current, { x: -800, y: -50 }, { duration: 0.001 });
		// show
		await animatePlayer1(player1Ref.current, { opacity: 1 }, { duration: 0.001 });
		// walk in
		await animatePlayer1(player1Ref.current, player1DefaultPos, { duration: 1 });
		// stand by
		setPlayer1State('standing');
		setPlayer1Ready(true);
	}

	async function player2Loaded() {
		// move off-screen
		await animatePlayer2(player2Ref.current, { x: 800, y: -50 }, { duration: 0.001 });
		// show
		await animatePlayer2(player2Ref.current, { opacity: 1 }, { duration: 0.001 });
		// walk in
		await animatePlayer2(player2Ref.current, player2DefaultPos, { duration: 1 });
		// stand by
		setPlayer2State('standing');
		setPlayer2Ready(true);
	}

	if (!dungeonState || !dungeonState.ActiveRaid) {
		return null;
	}

	const duelPlatformImageURL = dungeonState.ActiveRaid.DungeonImageURL || '/duel-platform.png';

	return (
		<div
			className={`relative flex flex-col font-silkscreen transition duration-500 min-w-[900px] max-w-[900px] min-h-[1050px] max-h-[1050px] overflow-hidden ${debugging ? 'border border-green-500 bg-gradient-to-br from-gray-900 to-gray-600' : ''} ${hideDungeon ? 'opacity-0' : 'opacity-100'}`}
			// for debugging
			onClick={onClick}
		>
			<audio ref={notifRef} src="/startsound.mp3" />

			{/* Header - Countdown, Events, and debug */}
			<div id="text-header" className="z-10 flex flex-col min-h-[200px] max-h-[200px]">
				<h1 className="grow mb-2 text-white text-center font-bold text-6xl shadow-pixel drop-shadow-strong">
					{fightResult === 'victory' ? '👑 WINNER 👑' : fightResult === 'stalemate' ? 'STALEMATE 🤷' : 'Dungeon Duel'}
				</h1>
				{eventMessages.length > 0 && (
					<div
						className="flex flex-col shrink-0 gap-2 items-end text-white text-4xl max-w-full"
						style={{
							filter: 'drop-shadow(0px 0px 4px #00000077)',
						}}
					>
						{eventMessages.map((message, i) => (
							<div key={i} className="relative text-right border-4 border-gray-500 bg-gray-800 px-4 py-1 mr-4 max-w-full">
								{message}
								<div className="absolute -bottom-1 -right-2 w-2 h-4 bg-gray-500"></div>
								<div className="absolute -bottom-1 -right-3 w-2 h-2 bg-gray-500"></div>
								<div className="absolute -bottom-[6px] -right-[14px] w-1 h-1 bg-gray-500"></div>
							</div>
						))}
					</div>
				)}
				<h1 className="mt-4 grow text-red-500 text-center font-bold text-6xl shadow-pixel drop-shadow-strong">
					{dungeonState.ActiveRaid.Status === 'waiting-for-party' && countdownSecs > 0 ? <>Starting in {Math.round(countdownSecs)}</> : ''}
				</h1>
				{debugging && (
					<div className="absolute top-0 right-0 text-right bg-red-900/70 text-white p-4">
						<h1>DEBUG</h1>
						<h1 className="font-medium">Dungeon state: {dungeonState?.ActiveRaid?.Status}</h1>
						<h2>Countdown: {Math.round(countdownSecs)}</h2>
					</div>
				)}
			</div>

			<div
				id="dungeon-container"
				className={
					'relative flex flex-col place-content-end z-0 self-center min-h-[800px] max-h-[800px] min-w-[800px] max-w-[800px] [image-rendering:pixelated] bg-contain bg-center bg-no-repeat' +
					(debugging ? ' border border-red-500' : '')
				}
			>
				<div className="characters relative z-0 h-[400px] w-full">
					<div
						id="winner-glow"
						className={`${fightResult === 'victory' ? 'opacity-100' : 'opacity-0'} absolute top-2/3 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[566px] h-[566px] rounded-full bg-orange-500 blur-[100px] transition-opacity duration-1000`}
					/>
					{player1 && (
						<Character
							key={player1.UserID}
							ref={player1Ref}
							player={player1}
							flip={false}
							state={player1State}
							health={playerHealth[0]}
							className="absolute bottom-0 left-1/2 opacity-0 !text-left"
							onLoaded={player1Loaded}
							noEnterAnimation
						/>
					)}
					<div
						key="challenger-placeholder"
						className={`absolute -bottom-10 left-[60%] flex items-center justify-items-center w-[250px] h-[380px] transition ${player2Ready || countdownSecs <= 0 ? 'opacity-0' : 'opacity-100'}`}
					>
						<div className="absolute top-0 left-0 w-full h-full bg-[#212020] blur-xl" />
						<p className="text-white text-center font-bold text-3xl shadow-pixel drop-shadow-strong">Waiting for challenger</p>
					</div>
					{player2 && (
						<Character
							key={player2.UserID}
							ref={player2Ref}
							player={player2}
							flip={true}
							state={player2State}
							health={playerHealth[1]}
							className="absolute bottom-0 left-1/2 opacity-0 !text-right"
							onLoaded={player2Loaded}
							noEnterAnimation
						/>
					)}
					{battleHitEffects.map((effect, i) => (
						<div className="absolute z-[9999] bottom-0 left-1/2 opacity-0 w-0" ref={battleHitEffectWrapperRefs[i]} key={`hit-${effect}-${i}`}>
							<video
								ref={battleHitEffectVideRefs[i]}
								src={'/effects/' + effect}
								width={440}
								height={440}
								className="min-w-[440px] min-h-[440px] max-w-[440px] max-h-[440px] [image-rendering:pixelated]"
								preload="auto"
								muted
								playsInline
							/>
						</div>
					))}
					{battleMissEffects.map((effect, i) => (
						<div className="absolute z-[9999] bottom-0 left-1/2 opacity-0 w-0" ref={battleMissEffectWrapperRefs[i]} key={`miss-${effect}-${i}`}>
							<video
								ref={battleMissEffectVideRefs[i]}
								src={'/effects/' + effect}
								width={440}
								height={440}
								className="min-w-[440px] min-h-[440px] max-w-[440px] max-h-[440px] [image-rendering:pixelated]"
								preload="auto"
								muted
								playsInline
							/>
						</div>
					))}
				</div>

				{/* duel platform */}
				<img src={duelPlatformImageURL} alt="" className="relative w-full [image-rendering:pixelated]" />
			</div>

			{/* Join CTA */}
			{dungeonState.ActiveRaid.Status === 'waiting-for-party' && (dungeonState.ActiveRaid.Players?.length ?? 0) < dungeonState.ActiveRaid.MaxPlayers && (
				<div className="h-12 flex items-end place-content-around w-full text-red-500 text-center font-bold text-[40px] shadow-pixel drop-shadow-strong">
					{dungeonState.ActiveRaid.ChallengedUserName &&
					countdownSecs > dungeonState.ActiveRaid.CountdownSeconds - (dungeonState.ActiveRaid.ChallengeSeconds ?? 10) ? (
						<p>
							Challenging <span className="whitespace-nowrap text-red-400">{dungeonState.ActiveRaid.ChallengedUserName}</span>! <br /> Send{' '}
							<span className="text-red-400">!join</span> to accept
						</p>
					) : (
						<p>
							Open Duel - send <span className="text-red-400">!join</span>
						</p>
					)}
				</div>
			)}

			<div className="absolute z-20 top-[80px] left-1/2 -translate-x-1/2 w-full">
				<MediaEventPlayer
					ref={mediaPlayerRef}
					volume={dungeonState.Settings?.NotificationSoundVolume}
					muted={!dungeonState.Settings?.EnableNotificationSound}
					className="w-full aspect-square"
				/>
			</div>

			{/* Preloading */}
			<div className="hidden">
				<img alt="background" src={duelPlatformImageURL} onLoad={() => setPreloadDone(true)} />
			</div>
		</div>
	);
}

export async function playVideoWait(video?: HTMLVideoElement | null, volume?: number) {
	// since this is typically a ref, this can happen if the component is unmounted
	if (!video) {
		return;
	}
	const played = new Promise((resolve, reject) => {
		video.onerror = reject;
		video.onended = resolve;
	});
	video.currentTime = 0;
	const oldAutoplay = video.autoplay;
	const oldLoop = video.loop;
	const oldVolume = video.volume;
	if (volume !== undefined) {
		video.volume = volume;
	}
	video.autoplay = false;
	video.loop = false;
	try {
		await video.play();
		await played;
	} catch (err: any) {
		console.error('failed to play video - skipping', err);
		trackError(err, { videoURL: video.src });
	} finally {
		video.loop = oldLoop;
		video.autoplay = oldAutoplay;
		video.volume = oldVolume;
	}
}

export function fromCurrent<T>(arr: T[]) {
	return [null as null | T].concat(arr);
}
