import React, { useRef } from 'react';
import { MediaEvent } from './types';
import { useAnimate } from 'framer-motion';
import { getRarityTheme } from './Character';
import { appVersion, trackError } from './App';

export interface MediaEventPlayerProps {
	volume?: number;
	muted?: boolean;
	className?: string;
}

export interface MediaEventPlayerRef {
	play(media: MediaEvent): Promise<void>;
}

export const MediaEventPlayer = React.forwardRef<MediaEventPlayerRef, MediaEventPlayerProps>((props, ref) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const imgRef = useRef<HTMLImageElement>(null);
	const [media, setMedia] = React.useState<MediaEvent | null>(null);

	const [containerRef, animateContainer] = useAnimate();

	const playerRef = useRef<MediaEventPlayerRef>({
		play: async (media: MediaEvent) => {
			if (!videoRef.current) {
				return;
			}
			try {
				setMedia(media);
				const showMs = (media.ShowSeconds ?? 3) * 1_000;
				// max time to wait for video in case it doesn't load properly
				const timeout = 10 * showMs;
				videoRef.current.volume = (props.volume ?? 0.7) * 0.8;
				videoRef.current.muted = props.muted ?? false;
				videoRef.current.src = (media.BackgroundVideoURL ?? './gift-animation.webm') + '?version=' + appVersion;
				const videoEnded = new Promise((resolve, reject) => {
					videoRef.current!.addEventListener('ended', resolve);
					new Promise((r) => setTimeout(() => r('timeout'), timeout));
					videoRef.current!.addEventListener('error', reject);
				});
				if (media.ImageURL) {
					const imgLoaded = new Promise((resolve, reject) => {
						imgRef.current!.addEventListener('load', resolve);
						new Promise((r) => setTimeout(() => r('timeout'), timeout));
						imgRef.current!.addEventListener('error', reject);
					});
					imgRef.current!.src = media.ImageURL;
					await imgLoaded;
				}
				await videoRef.current.play();
				// animate in
				await animateContainer(containerRef.current, { opacity: [0, 1], y: [150, 0] }, {});
				// video is done playing
				await videoEnded;
				// keep on screen for a bit
				await new Promise((r) => setTimeout(r, showMs));
			} catch (err: any) {
				console.error('failed to play media, skipping', err);
				trackError(err, { mediaURL: media.BackgroundVideoURL, imageURL: media.ImageURL });
			} finally {
				// animate out
				await animateContainer(containerRef.current, { opacity: 0, y: 150 }, {});
				imgRef.current!.src = '';
			}
		},
	});

	if (ref) {
		if ('current' in ref) {
			ref.current = playerRef.current;
		} else {
			ref(playerRef.current);
		}
	}

	return (
		<div className={`${props.className ?? ''} relative border border-transparent opacity-0`} ref={containerRef}>
			{media && (
				<div id="glow" className="absolute top-0 left-0 flex w-full h-full">
					<div
						className={`w-full mt-[60px] mb-[145px] bg-no-repeat bg-center bg-contain ${media.BackgroundColor && !/^#/.test(media.BackgroundColor) ? getRarityTheme(media.BackgroundColor).text : 'text-orange-500'}`}
						style={{
							color: media.BackgroundColor && /^#/.test(media.BackgroundColor) ? media.BackgroundColor : undefined,
							backgroundImage: `radial-gradient(ellipse, currentColor, currentColor 40%, transparent 70%)`,
						}}
					/>
				</div>
			)}
			<video ref={videoRef} className="w-full h-full absolute top-0 left-0" />
			<div className="relative mt-[290px] ml-[40px] mb-[320px] flex flex-col items-center text-center text-white shadow-pixel gap-3">
				<h1 className="text-4xl font-bold object-contain">{media?.Title}</h1>
				<img ref={imgRef} className="w-52 max-h-52 object-contain" alt="" />
				<div className="flex flex-col items-center gap-3 max-w-lg">
					<p className="text-4xl">{media?.Description}</p>
					<p className="text-4xl">{media?.Subtitle}</p>
				</div>
			</div>
		</div>
	);
});
