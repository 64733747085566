import { useEffect, useRef, useState } from 'react';
import { RaidDataRaid, RaidPlayer, RaidState } from './types';
import { Character } from './Character';
import { battleEffects, debugging, endRaid, initRaid, maxEventMessages, startRaid, timePerEventMs } from './App';
import { MediaEventPlayer, MediaEventPlayerRef } from './MediaEventPlayer';

// used to ensure React re-renders when the same effect is played multiple times
let effectKey = 0;

const battleEffectChancePerEvent = 0.65;

const _playerStarts = [
	{ x: -230, y: 220 },
	{ x: 270, y: 0 },
	{ x: -280, y: 40 },
	{ x: 160, y: 70 },
	{ x: -130, y: 150 },
	{ x: 230, y: 190 },
];

function getPlayerStart(index: number) {
	if (_playerStarts[index]) {
		return _playerStarts[index];
	}
	// should not happen, but better than crashing in case the backend is changed without updating this code
	const xRange = 600;
	const yRange = 200;
	_playerStarts[index] = {
		x: Math.round(Math.random() * xRange - xRange / 2),
		y: Math.round(Math.random() * yRange),
	};
	return _playerStarts[index];
}

const playerClasses = [
	'',
	'[&.standing_*]:![animation-delay:250ms] delay-[250ms]',
	'[&.standing_*]:![animation-delay:800ms] delay-[800ms]',
	'[&.standing_*]:![animation-delay:300ms] delay-[300ms]',
	'[&.standing_*]:![animation-delay:600ms] delay-[600ms]',
	'[&.standing_*]:![animation-delay:1000ms] delay-[750ms]',
];

export function RaidView(props: {
	dungeonState: RaidState<RaidDataRaid>;
	localClockDrift: number;
	onError: (err: any) => void;
	onClick: () => void;
	extraMsgs?: string[];
}) {
	const { dungeonState, localClockDrift, onError: handleError, onClick } = props;

	const animationPlaying = useRef(false);

	const [hideDungeon, setHideDungeon] = useState(true);
	const [countdownSecs, setCountdownSecs] = useState(-1);
	const [playerState, setPlayerState] = useState<'standing' | 'running'>('standing');
	const [playerMovement, setPlayerMovement] = useState<'entering' | 'exiting'>();
	const [livingPlayers, setLivingPlayers] = useState<RaidPlayer[]>([]);
	const [loadedPlayers, setLoadedPlayers] = useState(0);
	const [battleEffectQueue, setBattleEffectQueue] = useState<{ effect: string; key: number }[]>([]);

	const mediaPlayerRef = useRef<MediaEventPlayerRef>(null);

	const [allEventMessages, setEventMessages] = useState<string[]>([]);
	const eventMessages = allEventMessages.slice(-maxEventMessages);
	useEffect(() => {
		setEventMessages((old) => {
			const newExtraMsgs = (props.extraMsgs ?? []).filter((msg) => !old.includes(msg));
			return [...old, ...newExtraMsgs];
		});
	}, [props.extraMsgs]);

	function resetState() {
		setHideDungeon(true);
		setCountdownSecs(-1);
		setPlayerState('standing');
		setPlayerMovement(undefined);
		setLivingPlayers([]);
		setBattleEffectQueue([]);
		setEventMessages(() => []);
	}

	const notifRef = useRef<HTMLAudioElement>(null);

	useEffect(
		() => {
			if (!dungeonState?.ActiveRaid) {
				return;
			}

			console.log('update with state', dungeonState.ActiveRaid.Status, dungeonState.ActiveRaid.Players?.length);
			switch (dungeonState.ActiveRaid.Status) {
				case 'pending-start':
					console.log('pending-start - showing stuff on screen');
					resetState();
					initRaid(dungeonState.ActiveRaid.RaidID).catch(handleError);
					break;

				case 'waiting-for-party':
					setHideDungeon(false);
					console.log('waiting-for-party - starting countdown');
					const updateCountdown = () => {
						setCountdownSecs(() => {
							const startTime = new Date(dungeonState.ActiveRaid!.StartedAt);
							const nowUnixMs = new Date().getTime() - localClockDrift;
							const startedAtSecs = startTime.getTime() / 1000;
							const newSecs = nowUnixMs / 1000;
							const countdownLengthSecs = dungeonState.ActiveRaid!.CountdownSeconds;
							const secsRemaining = startedAtSecs - newSecs + countdownLengthSecs;
							// console.log('countdown', secsRemaining);
							const dungeonIsFull = dungeonState.ActiveRaid!.MaxPlayers === dungeonState.ActiveRaid!.Players?.length;
							if (secsRemaining < 0 || dungeonIsFull) {
								startRaid(dungeonState.ActiveRaid!.RaidID).catch(handleError);
								clearInterval(interval);
								return -1;
							}
							return secsRemaining;
						});
					};
					updateCountdown();
					setLivingPlayers((oldPlayers) => {
						const livingPlayers = dungeonState.ActiveRaid!.Players ?? [];
						const newlyJoinedPlayers = livingPlayers.filter((player) => !oldPlayers.some((oldPlayer) => oldPlayer.UserID === player.UserID));
						for (const player of newlyJoinedPlayers) {
							setEventMessages((old) => [...old, `${player.Name} joined the party`]);
						}
						return [...oldPlayers, ...newlyJoinedPlayers];
					});
					const interval = setInterval(updateCountdown, 500);
					if (dungeonState.Settings && dungeonState.Settings.EnableNotificationSound) {
						// play the audio
						if (!notifRef.current) {
							console.log('did not find notification current');
							return;
						}

						notifRef.current.volume = dungeonState.Settings.NotificationSoundVolume;
						notifRef.current.play();
					}
					return () => clearInterval(interval);

				case 'completed':
					if (animationPlaying.current) {
						// don't double play
						return;
					}
					setLivingPlayers(dungeonState.ActiveRaid.Players ?? []);
					if (loadedPlayers < dungeonState.ActiveRaid.Players?.length!) {
						return;
					}
					setHideDungeon(false);
					console.log('completed - playing the raid animation');
					const playAnimation = async () => {
						animationPlaying.current = true;
						setPlayerState('running');
						setPlayerMovement('entering');
						// let them enter the battle
						await new Promise((resolve) => setTimeout(resolve, 2_500));
						const events =
							dungeonState.ActiveRaid!.RaidEvents ??
							dungeonState.ActiveRaid!.Events?.map((msg) => ({
								Type: 'message',
								Message: msg,
							})) ??
							[];
						for (const event of events) {
							if (event.Type === 'media') {
								if (event.Message) {
									setEventMessages((old) => [...old, event.Message!]);
								}
								await mediaPlayerRef.current?.play(event);
								continue;
							}
							if (Math.random() < battleEffectChancePerEvent) {
								const rndEffectIdx = Math.floor(Math.random() * battleEffects.length);
								const nextEffect = battleEffects[rndEffectIdx];
								setBattleEffectQueue((old) => [...old, { effect: nextEffect, key: effectKey++ }]);
							}
							await new Promise((resolve) => setTimeout(resolve, timePerEventMs));
							setEventMessages((old) => [...old, event.Message]);
						}
						setLivingPlayers((old) => old.filter((player) => !dungeonState.ActiveRaid!.KilledPlayers?.includes(player.UserID)));
						setPlayerMovement('exiting');
						setBattleEffectQueue([]);
						await new Promise((resolve) => setTimeout(resolve, 3_000));
						setPlayerState('standing');
						setPlayerMovement(undefined);
						await new Promise((resolve) => setTimeout(resolve, 2_500));
						setHideDungeon(true);
						await new Promise((resolve) => setTimeout(resolve, 500));
						endRaid(dungeonState.ActiveRaid!.RaidID).catch(handleError);
						animationPlaying.current = false;
						console.log('animation done');
					};
					playAnimation();
					break;

				case 'archived':
					console.log('archived - this should not reach the frontend');
					break;

				default:
					resetState();
					break;
			}
		},
		// ensure we don't update for irrelevant changes
		[dungeonState?.ActiveRaid?.Status, dungeonState?.ActiveRaid?.Players?.length, loadedPlayers],
	);

	if (!dungeonState || !dungeonState.ActiveRaid) {
		return null;
	}

	return (
		<div
			className={`relative flex flex-col font-silkscreen transition duration-500 min-w-[900px] max-w-[900px] min-h-[1050px] max-h-[1050px] overflow-hidden ${debugging ? 'border border-green-500' : ''} ${hideDungeon ? 'opacity-0' : 'opacity-100'}`}
			// for debugging
			onClick={onClick}
		>
			<audio ref={notifRef} src="/startsound.mp3" />
			<div id="text-header" className="z-10 flex flex-col min-h-[200px] max-h-[200px]">
				{eventMessages.length > 0 && (
					<div
						className="flex flex-col shrink-0 gap-2 items-end text-white text-4xl max-w-full"
						style={{
							filter: 'drop-shadow(0px 0px 4px #00000077)',
						}}
					>
						{eventMessages.map((message, i) => (
							<div key={i} className="relative text-right border-4 border-gray-500 bg-gray-800 px-4 py-1 mr-4 max-w-full">
								{message}
								<div className="absolute -bottom-1 -right-2 w-2 h-4 bg-gray-500"></div>
								<div className="absolute -bottom-1 -right-3 w-2 h-2 bg-gray-500"></div>
								<div className="absolute -bottom-[6px] -right-[14px] w-1 h-1 bg-gray-500"></div>
							</div>
						))}
					</div>
				)}
				<h1 className="mt-4 grow text-red-500 text-center font-bold text-6xl shadow-pixel drop-shadow-strong">
					{dungeonState.ActiveRaid.Status === 'waiting-for-party' && countdownSecs > 0 ? <>Starting in {Math.round(countdownSecs)}</> : ''}
				</h1>
				{debugging && (
					<div className="absolute top-0 right-0 bg-red-900 text-white p-4">
						<h1>DEBUG</h1>
						<h1 className="font-medium">
							Dungeon state: {dungeonState?.ActiveRaid?.Status} Player move: {playerMovement} Player state: {playerState}
						</h1>
						<h2>Countdown: {Math.round(countdownSecs)}</h2>
					</div>
				)}
			</div>
			<div
				id="dungeon-container"
				className={
					'relative z-0 self-center min-h-[800px] max-h-[800px] min-w-[800px] max-w-[800px] [image-rendering:pixelated] bg-contain bg-center bg-no-repeat' +
					(debugging ? ' border border-red-500' : '')
				}
				style={{
					backgroundImage: `url(${dungeonState.ActiveRaid.DungeonImageURL})`,
				}}
			>
				<div className="characters absolute bottom-10 w-full">
					{livingPlayers.map((player, i) => (
						<Character
							key={player.UserID}
							player={player}
							flip={getPlayerStart(i).x > 0 !== (playerMovement === 'exiting')}
							state={playerState}
							className={`absolute bottom-0 left-1/2 transition duration-[3s] ${playerClasses[i]}`}
							onLoaded={async () => {
								// wait for entry animation to finish
								await new Promise((resolve) => setTimeout(resolve, 1_500));
								setLoadedPlayers((old) => old + 1);
							}}
							style={{
								transform:
									playerMovement === 'entering'
										? 'translateX(-50%) translateY(-50%)'
										: `translateX(calc(${getPlayerStart(i).x}px - 50%)) translateY(calc(${getPlayerStart(i).y}px - 50%))`,
								opacity: playerMovement === 'entering' ? 0 : 1,
								// ensure characters that are "in the front" are rendered on top
								zIndex: getPlayerStart(i).y + 1000,
							}}
						/>
					))}
				</div>
				{battleEffectQueue.length > 0 && (
					<div className="absolute left-1/2 top-[50%] -translate-x-1/2 -translate-y-1/2">
						<video
							key={battleEffectQueue[0].key}
							src={'/effects/' + battleEffectQueue[0].effect}
							className="w-[440px] h-[440px] [image-rendering:pixelated]"
							autoPlay
							muted
							playsInline
							onEnded={() => setBattleEffectQueue(battleEffectQueue.slice(1))}
						/>
					</div>
				)}
				<div className="absolute top-0 left-1/2 -translate-x-1/2 w-full h-full">
					<MediaEventPlayer
						ref={mediaPlayerRef}
						volume={dungeonState.Settings?.NotificationSoundVolume}
						muted={!dungeonState.Settings?.EnableNotificationSound}
						className="w-full aspect-square"
					/>
				</div>
			</div>
			{dungeonState.ActiveRaid.Status === 'waiting-for-party' && (dungeonState.ActiveRaid.Players?.length ?? 0) < dungeonState.ActiveRaid.MaxPlayers && (
				<h1 className="text-red-500 text-center font-bold text-[40px] shadow-pixel drop-shadow-strong">
					Send <span className="text-red-400">!join</span> to join {dungeonState.ActiveRaid.Players?.length}/{dungeonState.ActiveRaid.MaxPlayers}
					&nbsp;players
				</h1>
			)}
			<div className="hidden">
				{
					// for preloading
					battleEffects.map((effect) => (
						<img src={'/effects/' + effect} loading="eager" alt="battle effect" />
					))
				}
			</div>
		</div>
	);
}
