import React, { CSSProperties, useEffect, useState } from 'react';
import { RaidPlayer } from './types';

const rarityThemes: Record<string, { text: string; border: string; bg: string; icon: string; bgColor: string }> = {
	Common: {
		text: 'text-gray-200',
		border: 'border-gray-200',
		bg: 'bg-gray-900',
		bgColor: 'rgb(17 24 39)',
		icon: 'titles/common.png',
	},
	Uncommon: {
		text: 'text-green-200',
		border: 'border-green-200',
		bg: 'bg-green-900',
		bgColor: 'rgb(20 83 45)',
		icon: 'titles/uncommon.png',
	},
	Rare: {
		text: 'text-[#95DDE2]',
		border: 'border-[#95DDE2]',
		bg: 'bg-[#062944]',
		bgColor: '#062944',
		icon: 'titles/rare.png',
	},
	Epic: {
		text: 'text-[#FF51FF]',
		border: 'border-[#FF51FF]',
		bg: 'bg-[#3F084C]',
		bgColor: '#3F084C',
		icon: 'titles/epic.png',
	},
	Legendary: {
		text: 'text-orange-300',
		border: 'border-orange-300',
		bg: 'bg-orange-900',
		bgColor: 'rgb(124 45 18)',
		icon: 'titles/legendary.png',
	},
};

export function getRarityTheme(rarity: string) {
	const theme = rarityThemes[rarity];
	if (!theme) {
		console.error('unknown/missing rarity:', rarity);
		return rarityThemes['Common'];
	}
	return theme;
}

const hairStyles: {
	[key: string]: { Hinge: { X: number; Y: number }; Size: { Width: number; Height: number }; Path: string };
} = {
	'1': { Path: 'hair-1', Hinge: { X: -4, Y: -4 }, Size: { Width: 20, Height: 13 } },
	'2': { Path: 'hair-2', Hinge: { X: -1, Y: -4 }, Size: { Width: 17, Height: 18 } },
	'3': { Path: 'hair-3', Hinge: { X: -2, Y: -2 }, Size: { Width: 17, Height: 13 } },
	'4': { Path: 'hair-4', Hinge: { X: -1, Y: -2 }, Size: { Width: 15, Height: 12 } },
};

function getHair(hairStyle: string) {
	const style = hairStyles[hairStyle];
	if (!style) {
		if (hairStyle !== '0') {
			console.error('unknown hair style', hairStyle);
		}
		return undefined;
	}
	return style;
}

export interface CharacterProps {
	player: RaidPlayer;
	className?: string;
	style?: CSSProperties;
	state: 'standing' | 'running';
	flip?: boolean;
	health?: number;
	noEnterAnimation?: boolean;
	hideText?: boolean;
	onLoaded?: () => void;
	onError?: (err: any) => void;
}

export const Character = React.forwardRef<HTMLDivElement, CharacterProps>((props, ref) => {
	const title = props.player.Items?.find((item) => item.Type === 'title');
	const rarityTheme = title && getRarityTheme(title.Rarity);

	// we produce a fully inlined SVG, so it is always loaded at once (instead of the parts appearing independently)
	// and so it's definitely the same as in the extension
	const [avatarSVG, setAvatarSVG] = useState('');
	const [loadingRetry, setLoadingRetry] = useState(0);
	const [forcedState, setForcedState] = useState('');

	useEffect(() => {
		(async () => {
			const formData = new FormData();
			formData.append('player', JSON.stringify(props.player));
			try {
				const resp = await fetch('/create-avatar', {
					method: 'POST',
					body: formData,
				});
				if (!resp.ok) {
					console.error('failed to fetch avatar SVG', resp);
					props.onError?.(`wrong status code ${resp.status}`);
					return;
				}
				const avatarSVG = await resp.text();
				if (!props.noEnterAnimation) {
					// entering animation
					setForcedState('running !duration-[1s] !delay-0 transition');
					setTimeout(() => {
						setForcedState('');
					}, 1200);
				}
				setAvatarSVG(avatarSVG);
				props.onLoaded?.();
			} catch (err) {
				if (loadingRetry < 3) {
					await new Promise((r) => setTimeout(r, 1_000));
					setLoadingRetry(loadingRetry + 1);
					return;
				}
				console.error('failed to fetch avatar SVG', err);
				props.onError?.(err);
				return;
			}
		})();
	}, [props.player, loadingRetry]);

	const preLoadingStyle = !avatarSVG
		? {
				transform: props.flip ? 'translateX(100vw)' : 'translateX(-100vw)',
			}
		: {};

	return (
		<div
			ref={ref}
			className={`flex flex-col items-center text-center font-silkscreen w-min ${forcedState || props.state} ${props.className}`}
			style={{
				...props.style,
				...preLoadingStyle,
				filter: 'drop-shadow(6px -3px 3px #00000077)',
			}}
		>
			{avatarSVG && (
				<>
						<div className={`flex flex-col items-center transition duration-500 ${props.hideText ? 'opacity-0' : 'opacity-100'}`}>
							<h4 className={`${props.flip ? '-mr-16' : '-ml-16'} max-w-[300px] whitespace-nowrap truncate text-center text-white font-semibold text-4xl leading-none shadow-pixel shadow-[#323232]`}>
								<p className="text-gray-300 text-2xl leading-none">
									{props.player.IsPartyHost ? '👑 ' : ''}
									{props.player.Level ? `Lvl.${props.player.Level}` : ''}
								</p>{' '}
								{props.player.Name}
							</h4>
							{props.health !== undefined ? (
								<div className={`${props.flip ? '-mr-16' : '-ml-16'} relative flex mt-2 -mb-2 py-1 px-2 bg-gray-950 border border-gray-600 w-full`}>
									<div
										className={`absolute top-0 left-0 h-full transition-all ${(healthToBg(props.health))}`}
										style={{ width: `${props.health}%` }}
									/>
									<p className="relative text-white shadow-pixel">HP:</p>
									<p className="relative text-white shadow-pixel grow text-right">{props.health}/100</p>
								</div>
							) : (
								title && (
									<h5
										className={`${props.flip ? '-mr-16' : '-ml-16'} -mb-2 text-3xl font-semibold flex gap-1 items-center shadow-pixel ${rarityTheme!.text}`}
										style={{
											// @ts-ignore
											'--tw-shadow-color': rarityTheme!.bgColor,
										}}
									>
										{title.Title}
									</h5>
								)
							)}
						</div>
					<div dangerouslySetInnerHTML={{ __html: avatarSVG }} className={`w-[250px] h-[250px] ${props.flip ? '-scale-x-100' : ''}`} />
				</>
			)}
		</div>
	);
});

export function PlainCharacter(props: { player: RaidPlayer }) {
	const bodyArmor = props.player.Items?.find((item) => item.Type === 'body_armor');
	const weapon = props.player.Items?.find((item) => item.Type === 'weapon');
	const offhand = props.player.Items?.find((item) => item.Type === 'offhand');
	const helmet = props.player.Items?.find((item) => item.Type === 'helmet');
	const boot = props.player.Items?.find((item) => item.Type === 'boot');
	const glove = props.player.Items?.find((item) => item.Type === 'glove');
	const skinColor = props.player.SkinColor || '#FCB518';

	const hair = getHair(props.player.HairStyle);

	const gloveSecondaryImg = glove && (glove.SecondaryImage ?? glove.SrcURL);
	const gloveSecondarySize = glove && (glove.SecondarySize ?? glove.Size);
	const gloveSecondaryHinge = glove && (glove.SecondaryHinge ?? glove.Hinge);

	return (
		<svg viewBox="-4 0 48 45" xmlns="http://www.w3.org/2000/svg" className="character transition">
			<g className="left_leg" transform="translate(13 30)">
				<g className="inner">
					<mask id="left_leg_mask">
						<image href={'/items/left-leg/mask.svg'} width="8" height="10" />
					</mask>
					<rect fill={skinColor} width="8" height="10" mask="url(#left_leg_mask)" />
					<image href={'/items/left-leg/overlay.svg'} width="8" height="10" />

					{boot && (
						<g transform={`translate(${boot.Hinge.X} ${boot.Hinge.Y})`}>
							<image href={boot.SrcURL} preserveAspectRatio="none" width={boot.Size.Width} height={boot.Size.Height} />
						</g>
					)}
				</g>
			</g>

			<g className="right_leg" transform="translate(9 30)">
				<g className="inner">
					<mask id="right_leg_mask">
						<image href={'/items/right-leg/mask.svg'} width="8" height="10" />
					</mask>
					<rect fill={skinColor} width="8" height="10" mask="url(#right_leg_mask)" />
					<image href={'/items/right-leg/overlay.svg'} width="8" height="10" />

					{boot && (
						<g transform={`translate(${boot.Hinge.X} ${boot.Hinge.Y})`}>
							<image href={boot.SrcURL} preserveAspectRatio="none" width={boot.Size.Width} height={boot.Size.Height} />
						</g>
					)}
				</g>
			</g>

			<g className="upper_body">
				<g className="left_arm" transform="translate(14 21)">
					<g className="inner">
						{weapon && (
							<g transform={`translate(${weapon.Hinge.X} ${weapon.Hinge.Y})`}>
								<image
									href={weapon.SrcURL}
									transform="rotate(45)"
									transform-origin="bottom center"
									preserveAspectRatio="none"
									width={weapon.Size.Width}
									height={weapon.Size.Height}
								/>
							</g>
						)}

						<mask id="left_arm_mask">
							<image href={'/items/left-arm/mask.svg'} width="12" height="12" />
						</mask>
						<rect fill={skinColor} width="12" height="12" mask="url(#left_arm_mask)" />
						<image href={'/items/left-arm/overlay.svg'} width="12" height="12" />

						{glove && (
							<g transform={`translate(${12 + glove.Hinge.X - glove.Size.Width} ${11.5 + glove.Hinge.Y - glove.Size.Height})`}>
								<image href={glove.SrcURL} preserveAspectRatio="none" width={glove.Size.Width} height={glove.Size.Height} />
							</g>
						)}
					</g>
				</g>

				<g className="right_arm" transform="translate(0 21)">
					<g className="inner">
						<mask id="right_arm_mask">
							<image href={'/items/right-arm/mask.svg'} width="12" height="12" />
						</mask>
						<rect fill={skinColor} width="12" height="12" mask="url(#right_arm_mask)" />
						<image href={'/items/right-arm/overlay.svg'} width="12" height="12" />

						{glove && (
							<g transform={`translate(${1 + gloveSecondaryHinge!.X} ${11.5 + gloveSecondaryHinge!.Y - gloveSecondarySize!.Height})`}>
								<image href={gloveSecondaryImg} preserveAspectRatio="none" width={glove.Size.Width} height={glove.Size.Height} />
							</g>
						)}

						{offhand && (
							// this needs to be above the body, therefore the body part class is repeated here
							<rect width={offhand.Size.Width} height={offhand.Size.Height} transform={`translate(${offhand.Hinge.X} ${offhand.Hinge.Y})`} fill="transparent" />
						)}
					</g>
				</g>

				<g className="torso" transform="translate(8 20)">
					<g className="inner">
						<mask id="torso_mask">
							<image href={'/items/torso/mask.svg'} width="10" height="14" />
						</mask>
						<rect fill={skinColor} width="10" height="14" mask="url(#torso_mask)" />
						<image href={'/items/torso/overlay.svg'} width="10" height="14" />

						{bodyArmor && (
							<g transform={`translate(${bodyArmor.Hinge.X} ${bodyArmor.Hinge.Y})`}>
								<image href={bodyArmor.SrcURL} preserveAspectRatio="none" width={bodyArmor.Size.Width} height={bodyArmor.Size.Height} />
							</g>
						)}
					</g>
				</g>

				<g className="head" transform="translate(6 8)">
					<g className="inner">
						<mask id="head_mask">
							<image href={'/items/head/mask.svg'} width="13" height="14" />
						</mask>
						<rect fill={skinColor} width="13" height="14" mask="url(#head_mask)" />
						<image href={'/items/head/overlay.svg'} width="13" height="14" />

						{hair && (
							<>
								{helmet && (
									// the helmet should cover the hair, so for the top half we use the head as mask
									// and everything below 50% we keep
									<mask id="helmet_hair_mask" maskUnits="userSpaceOnUse">
										<image href={'/items/head/mask.svg'} y={-hair.Hinge.Y} x={-hair.Hinge.X} width="13" height="14" />
										<rect fill="white" y={-hair.Hinge.Y + 7} x={-hair.Hinge.X - 7} width="26" height="20" />
									</mask>
								)}
								<g id="hair" transform={`translate(${hair.Hinge.X} ${hair.Hinge.Y})`} mask={helmet && 'url(#helmet_hair_mask)'}>
									<mask id="hair_mask">
										<image href={`/items/${hair.Path}/mask.svg`} width={hair.Size.Width} height={hair.Size.Height} />
									</mask>
									<rect fill={props.player.HairColor} width={hair.Size.Width} height={hair.Size.Height} mask="url(#hair_mask)" />
									<image href={`/items/${hair.Path}/overlay.svg`} width={hair.Size.Width} height={hair.Size.Height} />
								</g>
							</>
						)}

						{helmet && (
							<g id="helmet" transform={`translate(${helmet.Hinge.X} ${helmet.Hinge.Y - 0.5})`}>
								<image href={helmet.SrcURL} preserveAspectRatio="none" width={helmet.Size.Width} height={helmet.Size.Height} />
							</g>
						)}
					</g>
				</g>

				{offhand && (
					// this needs to be above the body, therefore the body part class is repeated here
					<g className="right_arm" transform="translate(0 21)">
						<g className="inner">
							{/* this exists to make sure the bounding boxes are the same, while the actual weapon needs to be on a separate layer*/}
							<rect width="12" height="12" fill="transparent" />
							<g transform={`translate(${offhand.Hinge.X} ${offhand.Hinge.Y})`}>
								<image
									href={offhand.SrcURL}
									transform-origin="bottom center"
									preserveAspectRatio="none"
									width={offhand.Size.Width}
									height={offhand.Size.Height}
								/>
							</g>
						</g>
					</g>
				)}
			</g>
		</svg>
	);
}

export function healthToBg(healthPct: number) {
	return healthPct >= 50 ? 'bg-green-500' : healthPct >= 25 ? 'bg-yellow-500' : 'bg-red-500';
}
