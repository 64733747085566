import { Character } from './Character';
import { testData } from './debug';
import { useRef } from 'react';
import { MediaEventPlayer, MediaEventPlayerRef } from './MediaEventPlayer';

export function AvatarTestPage() {
	const playerRef = useRef<MediaEventPlayerRef>(null);

	return (
		<div className="flex flex-col font-silkscreen">
			<div className="grid grid-cols-2 max-w-[800px] mx-auto mt-20">
				<Character player={testData.ActiveRaid!.Players![0]} state="standing" flip={false} />
				<Character player={testData.ActiveRaid!.Players![0]} state="standing" flip={true} />
				<Character player={testData.ActiveRaid!.Players![0]} state="running" flip={false} />
				<Character player={testData.ActiveRaid!.Players![0]} state="running" flip={true} />
			</div>
			<div className="w-[800px] h-[800px]">
				<div className="flex gap-4">
					<button onClick={() => playerRef.current?.play(testData.ActiveRaid!.RaidEvents!.find((event) => event.Type === 'media')!.Media)}>play</button>
				</div>
				<MediaEventPlayer ref={playerRef} className="w-full h-full" />
			</div>
		</div>
	);
}
