import { backendAPI, streamKey, userID } from './global_config';
import { useSharedFunction, useSharedState } from 'frontlink';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { RaidDataBossFight, RaidDataDuel, RaidDataRaid, RaidState } from './types';
import { bossFightBossWinsTestData, bossFightTestData, duelTestData, testData } from './debug';
import { DuelView } from './Dueling';
import { RaidView } from './Raid';
import { BossFightView } from './BossFight';

export const battleHitEffects = ['battle-1.webm', 'battle-3.webm'];
export const battleMissEffects = ['puff.webm'];
export const battleEffects = [...battleHitEffects, ...battleMissEffects];

export const timePerEventMs = 3_000;
export const maxEventMessages = 3;

export const debugging = new URLSearchParams(window.location.search).get('debug');
if (debugging !== null) {
	console.log('Debugging enabled:', debugging);
}

// updated below from the version file. Used as a cache buster
export var appVersion = '';

export function App() {
	const [localClockDrift, setLocalClockDrift] = useState(0);

	const [dungeonState, setDungeonState] =
		debugging !== null
			? useState(
					debugging === 'duel' ? duelTestData : debugging === 'boss' ? bossFightTestData : debugging === 'boss-wins' ? bossFightBossWinsTestData : testData,
				)
			: useSharedState('dungeon::' + userID, null as RaidState<RaidDataRaid | RaidDataDuel | RaidDataBossFight> | null | undefined);

	const [extraMsgs, setExtraMsgs] = useState<string[]>([]);

	useEffect(() => {
		setExtraMsgs([]);
	}, [dungeonState?.ActiveRaid?.RaidID]);

	useSharedFunction('dungeon_extra_msg::' + userID, (msg: string) => {
		if (!msg) return;
		setExtraMsgs((old) => [...old, msg]);
	});

	const stateRef = useRef({ dungeonState });
	stateRef.current = { dungeonState };

	// tell overlay that we're ready
	useEffect(() => {
		if (!dungeonState) {
			return;
		}
		window.parent.postMessage({ type: 'dungeon', initialized: true }, '*');
		console.log('dungeon initialized');
	}, [!!dungeonState]);

	useEffect(() => {
		if (dungeonState?.ActiveRaid) {
			return;
		}
		(async () => {
			try {
				const res = await fetch(`/version`);
				if (res.ok) {
					const version = await res.text();
					if (appVersion && appVersion !== version) {
						const refreshURL = new URL(window.location.href);
						refreshURL.searchParams.set('version', version);
						window.location.href = refreshURL.toString();
					}
					appVersion = version;
				}
			} catch (err) {
				console.error('error fetching version', err);
			}
		})();
	}, [dungeonState?.ActiveRaid]);

	useLayoutEffect(() => {
		if (!dungeonState?.ServerTime) {
			return;
		}
		const serverTime = new Date(dungeonState.ServerTime);
		const localTime = new Date();
		const newClockDrift = localTime.getTime() - serverTime.getTime();
		console.log('new clock drift', newClockDrift, 'ms');
		setLocalClockDrift(newClockDrift);
	}, [dungeonState?.ServerTime]);

	// ensure no matter what bugs we have, that we don't show a dungeon forever
	useEffect(() => {
		if (!dungeonState?.ActiveRaid) {
			return;
		}
		const gracePeriodMs = 120_000;
		const countdownMs = dungeonState.ActiveRaid.CountdownSeconds * 1_000;
		let eventsCount = 0;
		if ('Events' in dungeonState.ActiveRaid && dungeonState.ActiveRaid.Events) {
			eventsCount = dungeonState.ActiveRaid.Events.length;
		} else if ('DuelEvents' in dungeonState.ActiveRaid && dungeonState.ActiveRaid.DuelEvents) {
			eventsCount = dungeonState.ActiveRaid.DuelEvents.length;
		} else if ('BossFightEvents' in dungeonState.ActiveRaid && dungeonState.ActiveRaid.BossFightEvents) {
			eventsCount = dungeonState.ActiveRaid.BossFightEvents.length;
		}
		const eventDurationMs = timePerEventMs * eventsCount;

		const dungeonTimeoutMs = countdownMs + eventDurationMs + gracePeriodMs + (debugging !== null ? 999_000 : 0);
		const timeout = setTimeout(() => {
			handleError('dungeon timed out! Force-removing it');
			trackMetric({ metric: 'timeout', value: 1, timeoutMs: dungeonTimeoutMs });
		}, dungeonTimeoutMs);

		console.debug('dungeon timeout started', dungeonTimeoutMs);
		return () => clearTimeout(timeout);
	}, [dungeonState?.ActiveRaid?.RaidID, dungeonState?.ActiveRaid?.Status]);

	function handleError(err: any) {
		trackError(err, {
			raidID: dungeonState?.ActiveRaid?.RaidID,
			raidStatus: dungeonState?.ActiveRaid?.Status,
		});
		console.error('error, killing raid', err);
		setDungeonState({ ActiveRaid: null });
		// marking it as ended so it doesn't constantly retry
		if (dungeonState?.ActiveRaid) {
			endRaid(dungeonState.ActiveRaid.RaidID).catch(handleError);
		}
	}

	function handleDebug() {
		if (!dungeonState) {
			return;
		}
		if (dungeonState.ActiveRaid?.Status === 'waiting-for-party') {
			if (dungeonState.ActiveRaid!.Players?.length! < dungeonState.ActiveRaid!.MaxPlayers) {
				dungeonState.ActiveRaid!.Players!.push({
					...testData.ActiveRaid!.Players![1],
					UserID: `fake-${dungeonState.ActiveRaid!.Players!.length}`,
					Name: `Faker Nr. ${dungeonState.ActiveRaid!.Players!.length}`,
					IsPartyHost: false,
					Level: (Math.floor(Math.random() * 10000) % 8) + 1,
				});
				return;
			} else {
				dungeonState.ActiveRaid!.Status = 'completed';
			}
			setDungeonState(JSON.parse(JSON.stringify(dungeonState)));
		}
	}

	if (!dungeonState || !dungeonState.ActiveRaid) {
		return null;
	}

	if (dungeonState.ActiveRaid.Type === 'duel') {
		return (
			<DuelView
				// we have a key here to be absolutely sure that we don't keep state between raids
				key={dungeonState.ActiveRaid.RaidID}
				dungeonState={dungeonState as RaidState<RaidDataDuel>}
				extraMsgs={extraMsgs}
				localClockDrift={localClockDrift}
				onError={handleError}
				onClick={handleDebug}
			/>
		);
	} else if (dungeonState.ActiveRaid.Type === 'boss') {
		return (
			<BossFightView
				// we have a key here to be absolutely sure that we don't keep state between raids
				key={dungeonState.ActiveRaid.RaidID}
				dungeonState={dungeonState as RaidState<RaidDataBossFight>}
				extraMsgs={extraMsgs}
				localClockDrift={localClockDrift}
				onError={handleError}
				onClick={handleDebug}
			/>
		);
	} else if (!dungeonState.ActiveRaid.Type || dungeonState.ActiveRaid.Type === 'raid') {
		return (
			<RaidView
				// we have a key here to be absolutely sure that we don't keep state between raids
				key={dungeonState.ActiveRaid.RaidID}
				dungeonState={dungeonState as RaidState<RaidDataRaid>}
				localClockDrift={localClockDrift}
				extraMsgs={extraMsgs}
				onError={handleError}
				onClick={handleDebug}
			/>
		);
	} else {
		console.error('unknown raid type', dungeonState.ActiveRaid.Type);
	}
	return null;
}

export interface MetricEvent {
	type: 'metric';
	metric: string;
	value: number;
}

export function trackMetric(event: Omit<MetricEvent, 'type'> & Record<string, any>) {
	window.parent.postMessage({ type: 'metric', ...event }, '*');
}

export function trackError(error: any, extra?: Record<string, any>) {
	window.parent.postMessage(
		{
			type: 'error',
			error: `${error}`,
			userID: userID,
			version: appVersion,
			...extra,
		},
		'*',
	);
}

export async function initRaid(raidID: string) {
	return await ensureRaidStateChange(raidID, 'init');
}

export async function startRaid(raidID: string) {
	return await ensureRaidStateChange(raidID, 'start');
}

export async function endRaid(raidID: string) {
	return await ensureRaidStateChange(raidID, 'end');
}

async function ensureRaidStateChange(raidID: string, state: 'init' | 'start' | 'end', retriesLeft = 3) {
	const url = `${backendAPI}/dungeon/raid/${raidID}/${state}?key=${streamKey}`;
	try {
		const res = await fetch(url, { method: 'POST' });
		if (res.ok) {
			console.log('ensureRaidStateChange - success', res.status, state, raidID);
		} else {
			console.log('ensureRaidStateChange - failed', res.status, state, raidID);
			if (retriesLeft > 0) {
				await new Promise((r) => setTimeout(r, 1_000));
				return await ensureRaidStateChange(raidID, state, retriesLeft - 1);
			}
		}
	} catch (err) {
		console.log('ensureRaidStateChange - error', err, state, raidID);
		if (retriesLeft > 0) {
			await new Promise((r) => setTimeout(r, 1_000));
			return await ensureRaidStateChange(raidID, state, retriesLeft - 1);
		}
	}
}
