import { useEffect, useRef, useState } from 'react';
import { Character, healthToBg } from './Character';
import { RaidDataBossFight, RaidPlayer, RaidState } from './types';
import { AnimatePresence, motion, useAnimate } from 'framer-motion';
import { battleHitEffects, battleMissEffects, debugging, endRaid, initRaid, maxEventMessages, startRaid, timePerEventMs } from './App';
import { MediaEventPlayer, MediaEventPlayerRef } from './MediaEventPlayer';
import { fromCurrent, playVideoWait } from './Dueling'; // player positions (x: -170 to -360, y: -60 to +40)

// player positions (x: -170 to -360, y: -60 to +40)
// NOTE: must be expanded when number of max players changes
const d = (440 - 170) / 9;
const playerDefaultPos = [
	// add some positions here
	{ x: -170 - 2 * d, y: 0, 'z-index': 10 },
	{ x: -170 - 5 * d, y: 0, 'z-index': 11 },
	{ x: -170 - 7 * d, y: 0, 'z-index': 12 },
	{ x: -170 - 3 * d, y: 0, 'z-index': 13 },
	{ x: -170 - 4 * d, y: 0, 'z-index': 14 },
	{ x: -170 - 6 * d, y: 0, 'z-index': 15 },
	{ x: -170 - 9 * d, y: 0, 'z-index': 16 },
	{ x: -170 - 1 * d, y: 0, 'z-index': 17 },
	{ x: -170 - 8 * d, y: 0, 'z-index': 18 },
	{ x: -170 - 0 * d, y: 0, 'z-index': 19 },
];

type animateFunction = ReturnType<typeof useAnimate>[1];

type Awaitable = { then: (cb: () => void) => void };

export interface RaidPlayerWithState extends RaidPlayer {
	Health: number;
	State: 'standing' | 'running';
}

export function BossFightView(props: {
	dungeonState: RaidState<RaidDataBossFight>;
	localClockDrift: number;
	onError: (err: any) => void;
	onClick: () => void;
	extraMsgs?: string[];
}) {
	const { dungeonState, localClockDrift, onError: handleError, onClick } = props;

	const bossName = 'Galdrog the Mountain Orc';

	const effectiveVolume = dungeonState.Settings?.EnableNotificationSound ? (dungeonState.Settings.NotificationSoundVolume ?? 0.7) : 0;

	const animationPlaying = useRef(false);

	const [preloadDone, setPreloadDone] = useState(false);
	const [countdownSecs, setCountdownSecs] = useState(20);

	const [fightResult, setFightResult] = useState<'chat-wins' | 'boss-wins'>();

	const [bossHealth, setBossHealth] = useState(100);
	const [bossMaxHealth, setBossMaxHealth] = useState(100);

	const [players, setPlayers] = useState<RaidPlayerWithState[]>([]);
	const [activePlayer, setActivePlayer] = useState<RaidPlayerWithState | null>(null);
	const [playersReady, setPlayersReady] = useState(0);
	const playerAnimations = [] as { ref: React.MutableRefObject<HTMLDivElement | null>; animate: animateFunction }[];
	for (let i = 0; i < dungeonState.ActiveRaid!.MaxPlayers; i++) {
		const [ref, animate] = useAnimate();
		playerAnimations.push({ ref, animate });
	}

	const mediaPlayerRef = useRef<MediaEventPlayerRef>(null);

	const [levelRef, animateLevel] = useAnimate();
	const bossAnimations = {} as Record<
		'idle' | 'atk' | 'yell',
		{
			wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
			videoRef: React.MutableRefObject<HTMLVideoElement | null>;
			animate: animateFunction;
			effect: string;
		}
	>;
	for (let state of ['idle', 'atk', 'yell'] as const) {
		const [wrapperRef, animate] = useAnimate();
		const videoRef = useRef<HTMLVideoElement | null>(null);
		bossAnimations[state] = { wrapperRef, videoRef, animate, effect: `orc_giant_${state}.webm` };
	}
	const battleHitEffectAnimations = [] as {
		wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
		videoRef: React.MutableRefObject<HTMLVideoElement | null>;
		animate: animateFunction;
		effect: string;
	}[];
	for (let i = 0; i < battleHitEffects.length; i++) {
		const [wrapperRef, animate] = useAnimate();
		const videoRef = useRef<HTMLVideoElement | null>(null);
		battleHitEffectAnimations.push({ wrapperRef, videoRef, animate, effect: battleHitEffects[i] });
	}
	const battleMissEffectAnimations = [] as {
		wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
		videoRef: React.MutableRefObject<HTMLVideoElement | null>;
		animate: animateFunction;
		effect: string;
	}[];
	for (let i = 0; i < battleMissEffects.length; i++) {
		const [wrapperRef, animate] = useAnimate();
		const videoRef = useRef<HTMLVideoElement | null>(null);
		battleMissEffectAnimations.push({ wrapperRef, videoRef, animate, effect: battleMissEffects[i] });
	}

	const [allEventMessages, setEventMessages] = useState<string[]>([]);
	const eventMessages = allEventMessages.slice(-maxEventMessages);
	useEffect(() => {
		setEventMessages((old) => {
			const newExtraMsgs = (props.extraMsgs ?? []).filter((msg) => !old.includes(msg));
			return [...old, ...newExtraMsgs];
		});
	}, [props.extraMsgs]);

	const introMusicRef = useRef<HTMLAudioElement>(null);

	async function showLevel() {
		await animateLevel(levelRef.current!, { opacity: 1 }, { duration: 0.5 });
	}

	async function hideLevel() {
		await animateLevel(levelRef.current!, { opacity: 0 }, { duration: 0.5 });
	}

	async function playRandomEffect(anims: typeof battleHitEffectAnimations, pos: { x: number; y: number }) {
		const effectIdx = Math.floor(Math.random() * anims.length);
		// move effect to the place of the fight
		await anims[effectIdx].animate(anims[effectIdx].wrapperRef.current!, { ...pos, opacity: 1 }, { duration: 0 });
		// play the effect
		await playVideoWait(anims[effectIdx].videoRef.current!, effectiveVolume);
		// hide it again
		await anims[effectIdx].animate(anims[effectIdx].wrapperRef.current!, { opacity: 0 }, { duration: 0 });
	}

	async function playRandomHitEffect(pos: { x: number; y: number }) {
		return await playRandomEffect(battleHitEffectAnimations, pos);
	}

	async function playRandomMissEffect(pos: { x: number; y: number }) {
		return await playRandomEffect(battleMissEffectAnimations, pos);
	}

	async function playBossEffect(animType: keyof typeof bossAnimations) {
		const bossAnim = bossAnimations[animType];
		// switch from idle to new anim
		await bossAnim.animate(bossAnim.wrapperRef.current!, { opacity: 1 }, { duration: 0 });
		await bossAnimations.idle.animate(bossAnimations.idle.wrapperRef.current!, { opacity: 0 }, { duration: 0 });
		// play the effect
		const effects: Awaitable[] = [playVideoWait(bossAnim.videoRef.current!, effectiveVolume)];
		if (animType === 'yell') {
			// yelling starts ~1s into the video
			await new Promise((resolve) => setTimeout(resolve, 1_000));
			const shakeLevel = animateLevel(
				levelRef.current!,
				{
					x: [1, -1, -3, 3, 1, -1, -3, 3, -1, 1, 1, 0],
					y: [1, -2, 0, 2, -1, 2, 1, 1, -1, 2, -2, 0],
					rotate: [0, -1, 1, 0, 1, -1, 0, -1, 1, 0, -1, 0],
				},
				{ duration: 0.25, repeat: 8 },
			);
			effects.push(shakeLevel);
		}
		await Promise.all(effects);
		// switch back to idle
		bossAnimations.idle.videoRef.current!.loop = true;
		bossAnimations.idle.videoRef.current!.autoplay = true;
		await bossAnimations.idle.animate(bossAnimations.idle.wrapperRef.current!, { opacity: 1 }, { duration: 0 });
		await bossAnim.animate(bossAnim.wrapperRef.current!, { opacity: 0 }, { duration: 0 });
	}

	useEffect(
		() => {
			if (!dungeonState) {
				return;
			}

			console.log('BOSS-FIGHT: update with state', dungeonState.ActiveRaid?.Status, dungeonState.ActiveRaid?.Players?.length);

			setPlayers((old) => {
				const newPlayers = dungeonState.ActiveRaid!.Players!.filter((player) => !old.some((oldPlayer) => oldPlayer.UserID === player.UserID));
				return [...old, ...newPlayers.map((player) => ({ ...player, Health: 100, State: 'running' as const }))];
			});

			switch (dungeonState.ActiveRaid?.Status) {
				case 'pending-start':
					console.log('BOSS-FIGHT: pending-start - showing stuff on screen');
					initRaid(dungeonState.ActiveRaid!.RaidID).catch(handleError);
					break;

				case 'waiting-for-party':
					if (!preloadDone) {
						return;
					}
					showLevel();
					console.log('BOSS-FIGHT: waiting-for-party - starting countdown');
					const updateCountdown = () => {
						setCountdownSecs(() => {
							const startTime = new Date(dungeonState.ActiveRaid!.StartedAt);
							const nowUnixMs = new Date().getTime() - localClockDrift;
							const startedAtSecs = startTime.getTime() / 1000;
							const newSecs = nowUnixMs / 1000;
							const countdownLengthSecs = dungeonState.ActiveRaid!.CountdownSeconds;
							const secsRemaining = startedAtSecs - newSecs + countdownLengthSecs;
							const dungeonIsFull = dungeonState.ActiveRaid!.MaxPlayers === dungeonState.ActiveRaid!.Players?.length;
							if (secsRemaining < 0 || dungeonIsFull) {
								startRaid(dungeonState.ActiveRaid!.RaidID).catch(handleError);
								clearInterval(interval);
								return -1;
							}
							return secsRemaining;
						});
					};
					updateCountdown();
					const interval = setInterval(updateCountdown, 500);
					if (dungeonState.Settings && dungeonState.Settings.EnableNotificationSound) {
						// play the audio
						if (!introMusicRef.current) {
							console.error('BOSS-FIGHT: did not find notification current');
							return;
						}

						introMusicRef.current.volume = effectiveVolume;
						introMusicRef.current.play().catch((err) => console.error('BOSS-FIGHT: failed to play intro music', err));
					}
					return () => clearInterval(interval);

				case 'completed':
					if (dungeonState.ActiveRaid!.Players?.length !== playersReady) {
						return;
					}
					if (animationPlaying.current) {
						// don't double play
						return;
					}
					// repeated here, in case we reloaded in the middle of the fight
					showLevel();
					console.log('BOSS-FIGHT: completed - playing the raid animation');

					const playAnimation = async () => {
						animationPlaying.current = true;
						// give some time to load the last player that joined
						await new Promise((resolve) => setTimeout(resolve, 500));

						const playersMap = Object.fromEntries(players.map((p) => [p.UserID, p]));

						for (const [i, event] of (dungeonState.ActiveRaid!.BossFightEvents ?? []).entries()) {
							setActivePlayer(null);
							if (event.Type === 'media') {
								if (event.Message) {
									setEventMessages((old) => [...old, event.Message!]);
								}
								await mediaPlayerRef.current?.play(event);
								continue;
							}
							switch (event.Type) {
								case 'initial': {
									setBossHealth(event.BossHealth);
									setBossMaxHealth(event.BossHealth);
									for (const [userID, health] of Object.entries(event.PlayerHealth)) {
										playersMap[userID].Health = health;
									}
									setPlayers((old) =>
										old.map((p) => {
											p.Health = event.PlayerHealth[p.UserID];
											return p;
										}),
									);
									// boss appears
									await playBossEffect('yell');
									break;
								}
								case 'attack': {
									let activeUserID = event.UserID;
									if (event.UserID === 'boss') {
										// the boss is standing even if it attacks and the player is running towards it
										activeUserID = event.TargetUserID!;
									}
									const activePlayer = playersMap[activeUserID!];
									setActivePlayer(activePlayer);
									const activePlayerIdx = players.findIndex((player) => player.UserID === activePlayer.UserID);
									const activePlayerPos = playerDefaultPos[activePlayerIdx];
									const bossPos = { x: 0, y: 0 };
									const animateActivePlayer = (props: any, opts?: any) =>
										playerAnimations[activePlayerIdx].animate(playerAnimations[activePlayerIdx].ref.current!, props, opts);
									// ensure we are on top of the other player
									await animateActivePlayer({ 'z-index': 20 + i }, { duration: 0 });
									const jumps = 4;
									// jump to the boss
									let attackPosX = bossPos.x;
									if (event.UserID === 'boss') {
										// the boss hits quite a bit in front of its own position
										attackPosX = bossPos.x - 80;
									}
									setPlayers((old) => {
										old[activePlayerIdx].State = 'running';
										return [...old];
									});
									await animateActivePlayer({
										x: fromCurrent(
											Array.from({ length: 2 * jumps }).map((_, i) => activePlayerPos.x + ((i + 1) * (attackPosX - activePlayerPos.x)) / (2 * jumps)),
										),
										y: fromCurrent(Array.from({ length: jumps }).flatMap(() => [bossPos.y - 50, bossPos.y])),
									});
									setPlayers((old) => {
										old[activePlayerIdx].State = 'standing';
										return [...old];
									});
									// boss attacks
									let bossMoveProm = Promise.resolve();
									if (event.UserID === 'boss') {
										bossMoveProm = playBossEffect('atk');
										// the weapon hits only after some time, so we delay the hit effect
										await new Promise((resolve) => setTimeout(resolve, 800));
									}
									// battle effect
									const effectPosX = event.UserID === 'boss' ? bossPos.x - 200 : bossPos.x;
									if (event.Message !== '0') {
										await playRandomHitEffect({ x: effectPosX, y: bossPos.y + 50 });
									} else {
										await playRandomMissEffect({ x: effectPosX, y: bossPos.y + 50 });
									}
									await bossMoveProm;
									// display result (update health and show messages)
									setPlayers((old) =>
										old.map((p) => {
											p.Health = event.PlayerHealth[p.UserID];
											return p;
										}),
									);
									setBossHealth(event.BossHealth);
									const attackerName = event.UserID === 'boss' ? 'The Boss' : activePlayer.Name;
									if (event.Message === '0') {
										setEventMessages((old) => [...old, `${attackerName} missed`]);
									} else {
										setEventMessages((old) => [...old, `${attackerName} dealt ${event.Message} damage`]);
									}
									// death animation
									if (event.PlayerHealth[activePlayer.UserID] <= 0) {
										await animateActivePlayer({ opacity: 0 }, { duration: 0.75 });
										break;
									}
									// jump back
									setPlayers((old) => {
										old[activePlayerIdx].State = 'running';
										return [...old];
									});
									await animateActivePlayer({
										x: fromCurrent(Array.from({ length: 2 * jumps }).map((_, i) => attackPosX - ((i + 1) * (attackPosX - activePlayerPos.x)) / (2 * jumps))),
										y: fromCurrent(Array.from({ length: jumps }).flatMap(() => [activePlayerPos.y - 50, activePlayerPos.y])),
									});
									setPlayers((old) => {
										old[activePlayerIdx].State = 'standing';
										return [...old];
									});
									break;
								}
								case 'victory': {
									setEventMessages((old) => [...old, event.Message!]);
									// victory animation for the boss
									if (event.UserID === 'boss') {
										setFightResult('boss-wins');
										await playBossEffect('yell');
										break;
									}
									setFightResult('chat-wins');
									// boss loses - fade out
									const fadeOut = bossAnimations.idle.animate(bossAnimations.idle.wrapperRef.current!, { opacity: 0 }, { duration: 1 });
									const winnerDances = playerAnimations.map(async (anim, i) => {
										// jump up and down
										await anim.animate(
											anim.ref.current!,
											{
												// jump five times
												y: fromCurrent(Array.from({ length: 5 }).flatMap(() => [playerDefaultPos[i].y - 25, playerDefaultPos[i].y])),
											},
											{ duration: 3, delay: 0.1 * i },
										);
									});
									await Promise.all([fadeOut, ...winnerDances]);
									break;
								}
								case 'stalemate': {
									setEventMessages((old) => [...old, event.Message!]);
									break;
								}
								case 'infamy': {
									const playerName = playersMap[event.UserID!].Name;
									setEventMessages((old) => [...old, `${playerName} ${Number(event.Message) >= 0 ? 'gained' : 'lost'} ${event.Message} infamy`]);
									break;
								}
								default: {
									if (event.Message) {
										setEventMessages((old) => [...old, event.Message!]);
										await new Promise((resolve) => setTimeout(resolve, timePerEventMs / 4));
									}
									break;
								}
							}
						}
						await new Promise((resolve) => setTimeout(resolve, 2_500));
						await hideLevel();
						endRaid(dungeonState.ActiveRaid!.RaidID).catch(handleError);
						console.log('BOSS-FIGHT: animation done');
					};
					playAnimation();
					break;

				case 'archived':
					console.log('BOSS-FIGHT: archived - this should not reach the frontend');
					break;

				default:
					console.error('BOSS-FIGHT: unknown state', dungeonState.ActiveRaid?.Status);
					break;
			}
		},
		// ensure we don't update for irrelevant changes
		[dungeonState?.ActiveRaid?.Status, dungeonState?.ActiveRaid?.Players?.length, playersReady, preloadDone],
	);

	async function playerLoaded(i: number) {
		const anim = playerAnimations[i];
		// move off-screen
		await anim.animate(anim.ref.current!, { x: -800, y: playerDefaultPos[i].y }, { duration: 0 });
		// show
		await anim.animate(anim.ref.current!, { opacity: 1 }, { duration: 0 });
		// walk in
		await anim.animate(anim.ref.current!, playerDefaultPos[i], { duration: 1 });
		// stand by
		setPlayers((old) => {
			old[i].State = 'standing';
			return [...old];
		});
		setPlayersReady((old) => old + 1);
	}

	if (!dungeonState || !dungeonState.ActiveRaid) {
		return null;
	}

	const duelPlatformImageURL = dungeonState.ActiveRaid.DungeonImageURL || '/duel-platform.png';

	return (
		<div
			ref={levelRef}
			className={`relative opacity-0 flex flex-col font-silkscreen min-w-[900px] max-w-[900px] min-h-[1050px] max-h-[1050px] overflow-hidden ${debugging ? 'border border-green-500 bg-gradient-to-br from-gray-900 to-gray-600' : ''}`}
			// for debugging
			onClick={onClick}
		>
			<audio ref={introMusicRef} src="/boss-fight/8bit-boss-battle-normalized.mp3" />

			{/* Header - Countdown, Events, and debug */}
			<div id="text-header" className="z-10 flex flex-col min-h-[200px] max-h-[200px]">
				<h1 className="grow mb-2 text-white text-center font-bold text-[46px] shadow-pixel drop-shadow-strong">
					{fightResult === 'chat-wins' ? '👑 CHAT WINS 👑' : fightResult === 'boss-wins' ? 'BOSS WINS 🧌' : bossName}
				</h1>

				{dungeonState.ActiveRaid.Status === 'waiting-for-party' && countdownSecs > 0 && (
					<h1 className="mt-4 grow text-red-500 text-center font-bold text-6xl shadow-pixel drop-shadow-strong">Starting in {Math.round(countdownSecs)}</h1>
				)}

				{eventMessages.length > 0 && (
					<div
						className="flex flex-col shrink-0 gap-2 items-end text-white text-4xl max-w-full"
						style={{
							filter: 'drop-shadow(0px 0px 4px #00000077)',
						}}
					>
						{eventMessages.map((message, i) => (
							<div key={i} className="relative text-right border-4 border-gray-500 bg-gray-800 px-4 py-1 mr-4 max-w-full">
								{message}
								<div className="absolute -bottom-1 -right-2 w-2 h-4 bg-gray-500"></div>
								<div className="absolute -bottom-1 -right-3 w-2 h-2 bg-gray-500"></div>
								<div className="absolute -bottom-[6px] -right-[14px] w-1 h-1 bg-gray-500"></div>
							</div>
						))}
					</div>
				)}

				{debugging && (
					<div className="absolute top-0 right-0 text-right bg-red-900/70 text-white p-4">
						<h1>
							DEBUG{' '}
							<button className="bg-gray-900" onClick={() => window.location.reload()}>
								reload
							</button>
						</h1>
						<h1 className="font-medium">Dungeon state: {dungeonState?.ActiveRaid?.Status}</h1>
						<h2>Countdown: {Math.round(countdownSecs)}</h2>
					</div>
				)}
			</div>

			<div
				id="dungeon-container"
				className={
					'relative flex flex-col place-content-end z-0 self-center min-h-[800px] max-h-[800px] min-w-[800px] max-w-[800px] [image-rendering:pixelated] bg-contain bg-center bg-no-repeat' +
					(debugging ? ' border border-red-500' : '')
				}
			>
				<div className="characters relative z-0 h-[400px] w-full -mb-12">
					{Object.entries(bossAnimations).map(([animKey, anim]) => (
						<div className={`absolute z-0 bottom-0 -right-8 ${animKey !== 'idle' && 'opacity-0'}`} ref={anim.wrapperRef} key={`boss-${animKey}`}>
							<video
								ref={anim.videoRef}
								src={'/boss-fight/' + anim.effect}
								width={600}
								height={600}
								className="min-w-[600px] min-h-[600px] max-w-[600px] max-h-[600px] [image-rendering:pixelated]"
								preload="auto"
								playsInline
								loop={animKey === 'idle'}
								autoPlay={animKey === 'idle'}
							/>
						</div>
					))}

					{players.map((player, i) => (
						<Character
							key={player.UserID}
							ref={playerAnimations[i].ref}
							player={player}
							flip={false}
							state={player.State}
							hideText={player.State === 'standing' && activePlayer !== player}
							className="absolute bottom-0 left-1/2 opacity-0 !text-left [&.standing_*]:![animation-delay:var(--standing-delay)]"
							style={{
								// @ts-ignore
								'--standing-delay': i * 50 + 'ms',
							}}
							onLoaded={() => playerLoaded(i)}
							noEnterAnimation
						/>
					))}

					{battleHitEffectAnimations.map((anim, i) => (
						<div className="absolute z-[9999] bottom-0 left-1/2 opacity-0 w-0" ref={anim.wrapperRef} key={`hit-${anim.effect}-${i}`}>
							<video
								ref={anim.videoRef}
								src={'/effects/' + anim.effect}
								width={440}
								height={440}
								className="min-w-[440px] min-h-[440px] max-w-[440px] max-h-[440px] [image-rendering:pixelated]"
								preload="auto"
								playsInline
							/>
						</div>
					))}
					{battleMissEffectAnimations.map((anim, i) => (
						<div className="absolute z-[9999] bottom-0 left-1/2 opacity-0 w-0" ref={anim.wrapperRef} key={`miss-${anim.effect}-${i}`}>
							<video
								ref={anim.videoRef}
								src={'/effects/' + anim.effect}
								width={440}
								height={440}
								className="min-w-[440px] min-h-[440px] max-w-[440px] max-h-[440px] [image-rendering:pixelated]"
								preload="auto"
								muted
								playsInline
							/>
						</div>
					))}
				</div>
				{/* background */}
				<img src={duelPlatformImageURL} alt="" className="[image-rendering:pixelated]" />
			</div>

			{/* Join CTA */}
			{dungeonState.ActiveRaid.Status === 'waiting-for-party' && (dungeonState.ActiveRaid.Players?.length ?? 0) < dungeonState.ActiveRaid.MaxPlayers ? (
				<div className="h-12 flex items-end place-content-around w-full text-red-500 text-center font-bold text-[40px] shadow-pixel drop-shadow-strong">
					<p>
						send <span className="text-red-400">!join</span> and fight for glory
					</p>
				</div>
			) : (
				dungeonState.ActiveRaid.Status === 'completed' && (
					<div id="health-table" className="absolute bottom-0 left-0 w-full flex flex-col gap-2 shadow-pixel">
						<AnimatePresence>
							{activePlayer && (
								<motion.div
									key={activePlayer.UserID}
									initial={{ opacity: 0, y: '-2rem' }}
									animate={{ opacity: 1, y: 0 }}
									exit={{ opacity: 0, y: '2rem' }}
									transition={{
										duration: 0.2,
										ease: 'easeInOut',
									}}
									className="flex flex-col transition"
									id="active-player-health"
								>
									<h5 className="text-white text-3xl whitespace-nowrap truncate">{activePlayer.Name}</h5>
									<div className="flex h-5 border border-gray-700">
										<div className={`${healthToBg(activePlayer.Health ?? 0)} transition-all`} style={{ width: `${activePlayer.Health ?? 0}%` }} />
									</div>
								</motion.div>
							)}
						</AnimatePresence>
						<div className="flex flex-col" id="boss-health">
							<h5 className="text-white text-3xl whitespace-nowrap truncate">{bossName}</h5>
							<div className="flex h-5 border border-gray-700">
								<div
									className={`${healthToBg((100 * bossHealth) / bossMaxHealth)} transition-all`}
									style={{ width: `${(100 * bossHealth) / bossMaxHealth}%` }}
								/>
							</div>
						</div>
					</div>
				)
			)}

			<div className="absolute z-20 top-[80px] left-1/2 -translate-x-1/2 w-full">
				<MediaEventPlayer
					ref={mediaPlayerRef}
					volume={effectiveVolume}
					muted={!dungeonState.Settings?.EnableNotificationSound}
					className="w-full aspect-square"
				/>
			</div>

			{/* Preloading */}
			<div className="hidden">
				<img alt="background" src={duelPlatformImageURL} onLoad={() => setPreloadDone(true)} />
			</div>
		</div>
	);
}
